<template>
	<div class="container">
    <div class="title">
      <span class="inner_title">修改密码</span>
    </div>
		<div>
			<div class="item-box">
				<span class="item-left">原始密码：</span>
				<el-input class="rantHouseName" v-model="old_password"   show-password  placeholder="请输入"></el-input>
			</div>
      <div class="item-box">
				<span class="item-left">新的密码：</span>
				<el-input class="rantHouseName" v-model="password"  show-password  placeholder="请输入"></el-input>
			</div>
      <div class="item-box">
				<span class="item-left">确认密码：</span>
				<el-input class="rantHouseName" v-model="password_confirmation"  show-password  placeholder="请输入"></el-input>
			</div>
			<div class="sub-box">
				<el-button class="submitBtn" type="primary" plain @click="submit">提交</el-button>
			</div>
		</div>
	</div>
</template>
<script>
  import {changePwdFn} from '@/api/login.js'
  // import {getLouDongListSelectFn,getDanYuanSelectFn} from '@/api/select.js'
	export default {
		props: [],
		data() {
			return {
        old_password:'',
        password:'',
        password_confirmation:'',
			};
		},
    created() {
		},
		mounted() {
			// this.getLouDongList()
		},
		methods: {
			//提交
			submit() {
        changePwdFn({
          api_token : localStorage.getItem('token1'),
					old_password:this.old_password,
          password:this.password,
          password_confirmation:this.password_confirmation,
				}).then(res => {
					if (res.code == 200) {
						this.$message({
              message:'修改成功',
              type:'success'
            })
					}
				});
			},
		},
	};
</script>
<style scoped>
	@import url("../../style/detail.css");
</style>
